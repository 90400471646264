<template>
    <MainLayout>
      <template #content>
        <div class="content-container flex flex-col w-full h-full items-center justify-center px-8 py-10">
          <Header1
            label="Guest Vouchers"
          />
          <div class="flex flex-col items-center">
            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas ut nunc porttitor, facilisis tellus sit amet, suscipit magna.
            </p>

            <form
              class="container w-full pt-8"
              @submit.prevent="onSubmit()"
            >
              <div class="flex flex-col w-full">
                <InputField
                  id="email"
                  v-model="email"
                  placeholder="E-Mail"
                  type="email"
                  rules="required"
                  :errorMessages="errorMessages.email"
                />

                <button
                  type="submit"
                  class="bg-peach px-5 py-3 rounded-md text-sm text-white mt-4 w-full"
                  :class="{'opacity-50': email === ''}"
                  :disabled="submitting || email === ''"
                >
                  Retrieve Vouchers
                </button>
              </div>
            </form>
          </div>
        </div>
      </template>
    </MainLayout>
  </template>
  <script>
    import MainLayout from '_layouts';
    import Button from '_components/Button';
    import Header1 from '_components/Headers/Header1';
    import InputField from '_components/Form/InputField';
  
    export default {
      name: 'Guest Vouchers',
      components: {
        MainLayout,
        Button,
        Header1,
        InputField
      },
      data() {
        return {
          submitting: false,
          email: '',
          errorMessages: [],
        }
      },
      methods: {
        async onSubmit() {
          console.log(this.email)
          try {
            await this.$store.commit('SET_IS_LOADING', { status: 'open' })
            this.submitting = true

            const { message } = await this.$store.dispatch('FETCH_GUEST_VOUCHERS', {email: this.email})

            await this.$store.commit('SET_IS_LOADING', { status: 'close' })
            this.submitting = false

            this.$swal({
              icon: 'success',
              title: 'Guest Vouchers',
              text: 'You will receive an email containing the vouchers that you have bought using the email that you just have entered.',
              confirmButtonColor: '#48BB78',
            })
          } catch (err) {
            await this.$store.commit('SET_IS_LOADING', { status: 'close' })
            this.submitting = false
            console.log(err)
          }
        },
      }
    }
  </script>
  <style lang='css' scoped>
  .container {
    /* margin-top: -100px; */
    width: 40%;
  }
  .content-container {
  }
  @media only screen and (max-width: 599px) {
    .container {
      width: 90% !important;
    }
  }
  @media only screen and (max-width: 767px) {
    .container {
      width: 60%;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .container {
      width: 50%;
    }
  }
</style>